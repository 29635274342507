<template>
  <div class="appointment-view">
    <app-header
      icon="heartbeat"
      title="Subespecialidade"
      goes-back
    ></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <div class="subspecialty">
            <form v-if="!isLoading" @submit.prevent>
              <div class="row">
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    name="name"
                    label="Nome"
                    v-model="subspecialty.name"
                  ></app-input>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <b-field label="Especialidade" required>
                    <b-select
                      v-model="subspecialty.specialty_id"
                      placeholder="Selecionar"
                      name="specialty_id"
                      required
                    >
                      <option
                        v-for="specialty in specialties"
                        :key="specialty.id"
                        :value="specialty.id"
                      >
                        {{ specialty.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <b-button
                    :disabled="$v.$invalid"
                    @click="onSubmit()"
                    type="is-primary"
                    >Salvar</b-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SubspecialtiesService from '@/services/subspecialties.service.js';
import SpecialtiesService from '@/services/specialties.service.js';
import { required } from 'vuelidate/lib/validators';
export default {
  data: () => ({
    errors: {},
    isLoading: true,
    title: '',
    subspecialty: {},
    specialties: [],
  }),
  validations: {
    subspecialty: {
      name: {
        required,
      },
      specialty_id: {
        required,
      },
    },
  },
  methods: {
    loadSubspecialty(id) {
      if (typeof id != 'undefined') {
        this.isLoading = true;
        SubspecialtiesService.getId(id, {
          include: ['specialty'],
        })
          .then(({ data }) => (this.subspecialty = data))
          .finally(() => (this.isLoading = false));
      } else {
        this.isLoading = false;
      }
    },
    loadSpecialties() {
      this.isLoading = true;
      SpecialtiesService.getAll()
        .then(({ data }) => (this.specialties = data))
        .finally(() => (this.isLoading = false));
    },
    onSubmit() {
      this.errors = {};

      !this.id && this.save(this.subspecialty);
      this.id && this.update(this.id, this.subspecialty);
    },
    save(data) {
      this.isSaving = true;
      SubspecialtiesService.save(data)
        .then(() => {
          this.$buefy.snackbar.open('Subespecialidade cadastrada com sucesso!');
          this.$router.push({ name: 'subspecialties.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Médico.',
            'error'
          );
          this.errors = response.data.message;
        })
        .finally(() => (this.isSaving = false));
    },
    update(id, data) {
      this.isSaving = true;
      SubspecialtiesService.update(id, data)
        .then(() => {
          this.$buefy.snackbar.open('Subspecialidade atualizada com sucesso!');
          this.$router.push({ name: 'subspecialties.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Médico.',
            'error'
          );
          this.errors = response.data.message;
        })
        .finally(() => (this.isSaving = false));
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.id = this.$route.params.id;

    this.loadSubspecialty(id);
    this.loadSpecialties();
  },
};
</script>

<style lang="scss" scoped>
.patient {
  display: flex;

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;

    img {
      object-fit: cover;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .links {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
